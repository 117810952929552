import React, { useState, useEffect } from "react";
import {DiscussionEmbed} from "disqus-react"; 
import { NavLink } from 'react-router-dom';
import './home.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faFacebook } from '@fortawesome/free-brands-svg-icons'; 

const Home = (props) => {  
  const disqusShortname = "villagehall-com"
  const disqusConfig = {
    url: "https://villagehall.com",
    identifier: 'article-id', // Single post id
    title: 'Title' // Single post title
  }                  

          return(
            <div>
            <div className='home'>
              <p className='intro'>
              Welcome to the site for South Hykeham village hall. 
              Elsewhere on the site you can find details about our clubs and our Instagram gallery.  
              </p>
              </div>
              <div className='contact'>
         
              <h2>Contact Details:</h2>
          <p>Tel.: 	01522698481</p>
          <p>Email: tobytwirl2@gmail.com</p>
          <p>Address: Boundary Ln, South Hykeham, Lincoln LN6 9NQ</p>

          <iframe title='google map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9564.759638073967!2d-0.616468!3d53.1785731!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x519404174909944d!2sSouth%20Hykeham%20Village%20Hall!5e0!3m2!1sen!2suk!4v1671465363266!5m2!1sen!2suk" width="600" height="450" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
              </div>


        )
    }

export default Home ;