import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
import Header from './components/header/header'; 
import Home from './components/home/home';
import Instagram from './components/instagram/instagram';
import Clubs from './components/clubs/clubs';
import Footer from './components/footer/footer'

function App() {
  return (
    <div className="App">
    <Router>
    <Routes>
      <Route exact path = '/' element={<div > <Header/> <Home /> <Footer /></div>}/>  
      <Route exact path = '/instagram' element={<div > <Header/> <Instagram/> <Footer /></div>}/> 
      <Route exact path = '/clubs' element={<div > <Header/> <Clubs /> <Footer /></div>}/> 
    </Routes>
  </Router>
      
    </div>
  );
}

export default App;
