import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import './clubs.css'

const Clubs = (props) => {                  

          return(
<div className='clubs'>
    <p>The following link is to the village hall's public documents, including our booking conditions and Premises Licence:  </p>
    <p><a target='_blank' href='https://drive.google.com/drive/folders/1PDbIgfJexedtEjbTXQP1yE_LG1n6PMZp?usp=share_link'>Documents</a></p>
<p>Please see below activities at the village hall, for further information, contact details and times on any of these, please click on the 'Documents' link above and go to the 'Activities' folder.</p>
<iframe title='list of clubs' className='clubs1' src="https://docs.google.com/document/d/e/2PACX-1vTbgdvdlFF3e1Fvr5KG1ZEjRBpzawrTOK4SrGjxiSshV8RfaveHqGRmD2w5PqbSuUTDwpcGdukeg5qv/pub?embedded=true"></iframe>
</div>

        )
    }

export default Clubs; 