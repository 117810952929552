import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import './header.css'; 

const Header = () => {
  const [display, setDisplay] = useState(false);

  const changeDisplay = () => {
    if(display){setDisplay(false)}
    else if(!display){setDisplay(true)}
  }

          return(
              <div className='header'>
                <div className='heading'>
               <img className='hall-img' alt='village hall' src='https://live.staticflickr.com/65535/52617132931_38acdc9458_w.jpg'></img>
               <div className='heading-text'>
               <h1>South Hykeham Village Hall</h1> 
               <p>Charity No.: 503154</p>
               </div>
             </div>
             <div className='navbar'>
                <button onClick={(e) => {changeDisplay()}}>Navbar</button>
                {display &&
                  <div className='NavLinks'>
                <div className='NavLinkDiv'>
               <NavLink to='/' className='NavLink'>Home</NavLink> 
              </div>
              <div className='NavLinkDiv'>
               <NavLink to='/clubs' className='NavLink'>Club Info & Docs</NavLink>
               </div>
               <div className='NavLinkDiv'>
               <NavLink to='/instagram' className='NavLink'>Instagram Gallery</NavLink>
               </div>         

               </div>
               }
              </div> 
              </div>

        )
    }

export default Header ;